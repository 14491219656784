import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})

export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;

    } else {
      this.router.navigate(["/auth/login"]);
      return false;
    }
  }
}
