import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { map, catchError } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { TokenApiModel } from "../models/users/token.model";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string>;
  forbiddenUrls: Array<string> = [
    "api/token",
    "http://localhost:4200/auth/login",
  ];
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.urlsToNotUse = ["/login", "/register"];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.forbiddenUrls.some((substring) => request.url.includes(substring))
    ) {
      // No interceptar la petición y pasarla al siguiente interceptor en la cadena
      return next.handle(request);
    }
    if (this.isValidRequestForInterceptor(request.url)) {
      const access_token = this.authService.getToken();
      if (access_token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${access_token}`,
          },
        });
      }
      return next.handle(request).pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              location.reload();
              return this.handleUnAuthorizedError(request, next);
            }
            if (err.status === 400) {
              return throwError(() => new Error(err.error.detail));
            }
          }
          return throwError(() => new Error("Error distinto a 401"));
        })
      );
    } else {
      return next.handle(request);
    }
  }

  handleUnAuthorizedError(request: HttpRequest<any>, next: HttpHandler) {
    let tokenApiModel = new TokenApiModel();
    tokenApiModel.refresh_token = this.authService.getRefreshToken();

    if (tokenApiModel.refresh_token) {
      return this.authService.refreshToken(tokenApiModel).pipe(
        switchMap((data: TokenApiModel) => {
          this.authService.setRefreshToken(data.refresh_token);
          this.authService.setToken(data.token);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${data.token}`,
            },
          });
          return next.handle(request);
        }),
        catchError((err) => {
          return throwError(() => {
            console.log("Error al refrescar el token");
            this.authService.clearAll();
            this.router.navigate(["/"]);
          });
        })
      );
    } else {
      console.log("No existe token de refresco");
      this.authService.clearAll();
      this.router.navigate(["/"]);
    }
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = "/";
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(
        position + positionIndicator.length
      );
      for (let address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }
}
