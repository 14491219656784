import { Injectable } from "@angular/core";
 import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private defaultLanguage: string = "es";

  constructor(private translate: TranslateService) {}

  setDefaultLanguage(language: string) {
    this.translate.setDefaultLang(language);
  }

  getCurrentLanguage(): string {
    let lang = localStorage.getItem("language");

    if (!lang) {
      lang = this.translate.currentLang
        ? this.translate.currentLang
        : this.defaultLanguage;
    }

    return lang;
  }

  useLanguage(language: string) {
    localStorage.setItem("language", language);
    this.translate.use(language);
  }
}
