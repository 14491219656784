import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "../app-settings";
import { catchError, map } from "rxjs/operators";
import { of, Observable, throwError } from "rxjs";
import jwtDecode from "jwt-decode";
import { TokenApiModel } from "../models/users/token.model";
import * as bcrypt from "bcryptjs";
import { NbAuthToken, NbTokenService } from "@nebular/auth";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment.prod";

interface DecodedToken {
  exp: number;
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private tokenService: NbTokenService,
    private router: Router
  ) {}

  getAdminCheck() {
    return localStorage.getItem("admin_check");
  }

  setAdminCheck(flag: string) {
    localStorage.setItem("admin_check", flag);
  }

  getToken() {
    return localStorage.getItem("access_token");
  }

  getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  getHost() {
    return localStorage.getItem("vhost");
  }

  getDataToken() {
    return jwtDecode(this.getToken());
  }

  setToken(token: string) {
    console.log("Setting token:", token);
    localStorage.setItem("access_token", token);
  }

  setRefreshToken(refresh_token: string) {
    localStorage.setItem("refresh_token", refresh_token);
  }

  clearToken() {
    localStorage.removeItem("access_token");
  }

  clearAll() {
    localStorage.clear();
  }

  hashPassword(password: string) {
    const salt = bcrypt.genSaltSync(10);
    const hashPassword = bcrypt.hashSync(password, salt);
    return hashPassword;
  }

  refreshToken(tokenApiModel: TokenApiModel): Observable<any> {
    const API_BACKEND = this.getHost();

    const formHeaders = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.put<any>(
      API_BACKEND + "/api/login",
      tokenApiModel,
      formHeaders
    );
  }


  isAuthenticated(): boolean {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token: string): boolean {
    const decoded: DecodedToken = jwtDecode(token);
    const currentTime = new Date().getTime() / 1000;
    return decoded.exp < currentTime;
  }

  checkVhost() {
    const vhost = this.getHost();
    if (!vhost) {
      const token = this.getToken();
      console.log("Token:", token);
      if (token) {
        const decoded = jwtDecode(token);
        const email = decoded["email"];
        const password = "";
        this.setHost(email, password).subscribe((response) => {
          if (response) {
            location.reload();
          }
        });
      }
    }
  }

  private localHost = "http://localhost";
  private productionHost = "https://dmelec-api.idoneolab.com";

  setHost(email: string, password: string): Observable<string> {
    let vhost: string;

    if (environment.production) {
      vhost = this.productionHost;
    } else {
      vhost = this.localHost;
    }

    localStorage.setItem("vhost", vhost);

    const formHeaders = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const requestData = {
      email: email,
      password: password,
      token: this.getToken(),
    };

    return this.http
      .post<any>(vhost + "/api/login", requestData, formHeaders)
      .pipe(
        map(() => vhost),
        catchError((error) => {
          console.log("ERROR setHost");
          console.log(error);
          return throwError(error.error);
        })
      );
  }

  login(email: string, password: string) {
    const API_BACKEND = this.getHost();

    const formHeaders = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http
      .post<any>(
        API_BACKEND + "/api/login",
        { email: email, password: password },
        formHeaders
      )
      .pipe(
        map((jwt) => {
          if (jwt && jwt.access_token) {
            this.setToken(jwt.access_token);
            console.log("Token set successfully.");
            return true;
          } else {
            return false;
          }
        }),
        catchError((error) => {
          console.error("API error:", error);
          return of(false);
        })
      );
  }

  logout() {
    this.clearAll();
    this.router.navigate(["/auth/login"]);
  }

  register(
    name: string,
    email: string,
    password: string,
    confirm_password: string
  ): Observable<any> {
    const API_BACKEND = this.getHost();

    const formHeaders = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const requestData = {
      name: name,
      email: email,
      password: password,
      confirm_password: confirm_password,
    };

    return this.http.post<any>(
      API_BACKEND + "/api/register",
      requestData,
      formHeaders
    );
  }
}
